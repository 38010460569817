
import { getSessionUserInformation } from "app/utils/utility.service";
import {  useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./Partials.scss";
import { Card } from "primereact/card";
const Header = ({handleSidebar}) => {
    return (
        <div className="container-fluid">
            <div className="card">
                <Card className="header-card">
                    <div className="Header">
                            <div>
                                <img
                                    src="/Millenium_Logo.png"
                                    alt="Millenniun Semiconductors"
                                    title="Millenniun Semiconductors"
                                />
                            </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Header;
