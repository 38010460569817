import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";

const Step1 = Loadable(lazy(() => import("./login/Step1")));
const Step2 = Loadable(lazy(() => import("./login/Step2")));
const Register = Loadable(lazy(() => import("./register/Register")));
const ForgotPassword = Loadable(lazy(() => import("./forgot-password/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("./reset-password/ResetPassword")));
const MyProfile = Loadable(lazy(() => import("./my-profile/MyProfile")));


const sessionRoutes = [
    {
        path: "/session/signup",
        element: <Register />,
    },
    {
        path: "/session/signin",
        element: <Step1 />,
    },
    {
        path: "/scannedQR",
        element: <Step2 />,
    },
    {
        path: "/session/forgot-password",
        element: <ForgotPassword />,
    },
    {
        path:"/reset-password",
        element: <ResetPassword />
    },
    {
        path:"/my-profile",
        element: <MyProfile />
    }
];

export default sessionRoutes;
