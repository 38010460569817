import { getSessionUserInformation } from "app/utils/utility.service";
import { Navigate } from "react-router-dom";
import { DefaultLayout } from "../components";
import homePurchaseRoutes from "../views/home-purchase/HomePurchaseRoutes";
import sessionRoutes from "../views/sessions/SessionRoutes";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import brokerRoutes from "app/views/brokers/BrokerRoutes";
import agentRoutes from "app/views/agents/AgentRoutes";
import clientRoutes from "app/views/clients/ClientRoutes";
import reportsRoutes from "app/views/reports/ReportsRoutes";

export const AllPages = () => {
    const sessionValues = getSessionUserInformation();
    const all_routes = [
        {
            element: <DefaultLayout />,
            children: [
                ...sessionRoutes,
            ],
        },
    ];
    if (!!sessionValues?.firstName) {
        all_routes.push({
            path: "/",
            element: <Navigate to="/scannedQR" />,
        });
    } else {
        all_routes.push({
            path: "/",
            element: <Navigate to="/session/signin" />,
        });
    }
    return all_routes;
};
